#categories ul,
#categories li,
#categories a {
	color: #000000;
	font-size: 11px;
	line-height: 16px;
	list-style-type: none;
	margin-top: 0px;
	margin-left: 0px;
	padding-left: 0px;
	text-decoration: none;
}
#categories a:hover {
	color: #871A2A;
	text-decoration: underline;
}
.header {
	padding: 46px 19px 0;
	position: relative;
	.topCopy { border-bottom: 1px solid #ccc; height: 75px; margin-right: 218px; }
	h1 {
		color: #000;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 2.2em;
		font-weight: 500;
		margin: 0 0 20px;
	}
	img { bottom: 2px; position: absolute; right: 54px; }
	.contactOptions {
		font-size: 1.45rem;
		font-weight: 500;
		margin: 35px 200px 35px 0px;
		text-align: left;

		[class^='lpIcon-'] { font-size: 18px; margin: auto .2em; }
		.bigger { font-size: 1.33em; }
		.slightlyBigger { font-size: 1.15em; font-weight: normal; }
		.chatTextBefore { font-weight: normal; }
		.chatWrapper { margin-left: 5px; }
	}
}
.shopByCategory {
	padding: 20px;
	h2 { margin: 20px 0px 26px; }
	a { text-decoration: none; }
}
.moreWaysToShop {
	margin-bottom: 18px;
	padding: 20px;
	h2 { margin-bottom: 26px; }
	a {
		text-decoration: none;
		text-align: center;
		strong { font-size: 1.3em; line-height: 1.3em; text-transform: uppercase; }
		p { margin: 0; }
		&:first-child { margin-left: 0; }
		&:nth-child(3n) { margin-right: 0; }
	}
}
.certonaHdr {
	display: block;
    font-size: 1.04em;
	font-weight: 500;
	padding: 17px 0 0;
	text-transform: uppercase;
}
.bucket3Across {
	a {
		border: 1px solid #ccc;
		display: inline-block;
		margin: 0 5px;
		padding: 23px;
		width: 249px;
	}
}
.bucket4Across {
	margin-bottom: 30px;
	a {
		display: inline-block;
		margin-left: 22px;
		text-align: center;
		width: 210px;
		&:nth-child(4n+5), &:first-child { margin-left: 0; }
		p {
			font-size: 1.45rem;
			font-weight: 500;
			margin: .3em auto;
		}
	}
}
